// @flow
// eslint-disable react/display-name

import { ComponentType, Node } from 'react'
import React, { useReducer } from 'react'

import { AuthDispatchContext, useAuth } from './dispatch'
import {
  AuthStateContext,
  DEFAULT_STATE,
  stateReducer,
  useAuthState
} from './state'

type AuthProviderProps = { children: Node }

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(stateReducer, DEFAULT_STATE)

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

const withAuth = (component: ComponentType<any>) => (props: any) =>
  React.createElement(component, props)

export { AuthProvider, withAuth, useAuth, useAuthState }
