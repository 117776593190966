// @flow
import React, { useCallback, useContext } from 'react'

import type { Dispatch } from './types'

export const AuthDispatchContext = React.createContext<Dispatch>(() => {})

export const useAuth = () => {
  const dispatch = useContext(AuthDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  const setLoading = useCallback(
    (loading: boolean) => dispatch({ type: 'SET_LOADING', loading }),
    [dispatch]
  )

  const signIn = useCallback(
    (data, callback) => {
      // Query user and account table
      setLoading(true)
      try {
        window.localStorage.setItem('RitualToken', data)
        dispatch({ type: 'SIGN_IN', payload: data })
        if (callback) {
          callback()
        }
      } finally {
        setLoading(false)
      }
    },
    [dispatch, setLoading]
  )

  const signOut = useCallback(
    (callback) => {
      setLoading(true)
      try {
        window.localStorage.removeItem('RitualToken')
        dispatch({ type: 'SIGN_OUT' })
        if (callback) {
          callback()
        }
      } finally {
        setLoading(false)
      }
    },
    [dispatch, setLoading]
  )

  const restore = useCallback(
    (data, callback) => {
      setLoading(true)
      try {
        dispatch({ type: 'SIGN_IN', payload: data })
        if (callback) {
          callback()
        }
      } finally {
        setLoading(false)
      }
    },
    [dispatch, setLoading]
  )

  return { signIn, signOut, restore }
}
