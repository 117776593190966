// @flow
import React, { useContext } from 'react'
import type { Action, State } from './types'

export const DEFAULT_STATE = {
  user: null,
  token: null,
  loading: true
}

export const AuthStateContext = React.createContext<State>(DEFAULT_STATE)

export const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SIGN_IN': {
      return {
        ...state,
        token: action.payload
      }
    }

    case 'SIGN_OUT':
      return {
        ...state,
        token: null
      }

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const useAuthState = () => {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }
  return context
}
