import React, { Suspense } from 'react'
import { AuthProvider } from 'context/auth/index.js'
import Loading from '../loading'
import styles from './core.module.css'

// lazy load the children components so that spinner shows.
// todo: wrap in HOC

const Layout = React.lazy(() => import('./root'))

function Core() {
  return (
    <AuthProvider>
      <Suspense fallback={<Loading />}>
        <div className={styles.wrapper}>
          <Layout />
        </div>
      </Suspense>
    </AuthProvider>
  )
}

export default Core
